<template>

	<div class="week-container">

		<div class="days-desktop" v-if="!is_phone()">

			<a :href="base_url + deck_before.date.format( 'YYYY-MM-DD' )" v-if="!is_phone()">&#60; D#{{ deck_before.deck }}</a>
			<a v-for="( day, key ) in days" v-bind:key="key" v-bind:class="{ current: day.current }" :href="base_url + day.date.format( 'YYYY-MM-DD' )" v-html="day_string( day )" />
			<a :href="base_url + deck_after.date.format( 'YYYY-MM-DD' )" v-if="!is_phone()">D#{{ deck_after.deck }} &#62;</a>

		</div>

		<div class="days-mobile" v-if="is_phone()">

			<vselect
				:options="get_options_days()"
				:select="day.format( 'YYYY-MM-DD' )"
				:onchange="change_day"
			/>

			<vselect
				v-if="users_option.length > 0"
				ref="user_select"
				id="user-select-mobile"
				:options="users_option"
				:select="current_user_id"
				:onchange="change_user"
				:placeholder="'Je suis...'"
			/>

			<!-- <vselect
				ref="user_select"
				id="user-select-mobile"
				:options="get_options_users()"
				:select="get_user_id"
				:onchange="change_user"
				:placeholder="'Je suis...'"
			/> -->

		</div>

	</div>

</template>

<script>

	import moment from 'moment'
	moment.locale( 'fr' )

	import mixins_global from '@/mixins/global'
	import device from '@/mixins/device'

	import Deck from '@/class/Deck.class'

	export default {
		
		name	: 'week',

		components	: {

			vselect	: () => import( '@/components/form/vselect.vue' ),

		},

		mixins	: [ mixins_global, device ],

		props	: [ 'day', 'base_url' ],

		data	: function() { return {

			months	: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
			days_string	: [
				"LUN", "MAR", "MER", "JEU", "VEN", "SAM", "DIM",
			],
			days	: [],

			deck_before	: {
				date	: moment(),
				deck	: 1,
			},
			deck_after	: {
				date	: moment(),
				deck	: 1,
			},

		}},

		methods	: {

			/**
			 */

			get_options_days() {

				var _this	= this
				var options	= [{
					label	: 'Deck précédent',
					value	: this.deck_before.date.format( 'YYYY-MM-DD' ),
				}]

				this.days.forEach(function( day ) {

					options.push({
						label	: _this.day_string( day ),
						value	: day.date.format( 'YYYY-MM-DD' ),
					})

				})

				options.push({
					label	: 'Deck suivant',
					value	: this.deck_after.date.format( 'YYYY-MM-DD' ),
				})

				return options

			},

			/**
			 */
			
			day_string( day ) {

				if ( this.is_phone() ) {

					if ( moment().format( 'D' ) == day.date.format( 'D' ) ) {
						return "Aujourd'hui " + day.date.format( 'DD' )
					} else if ( moment().subtract( 1, 'days' ).format( 'D' ) == day.date.format( 'D' ) ) {
						return "Hier " + day.date.format( 'DD' )
					} else if ( moment().add( 1, 'days' ).format( 'D' ) == day.date.format( 'D' ) ) {
						return "Demain " + day.date.format( 'DD' )
					} else {
						return day.date.format( 'dddd' ) + " " + day.date.format( 'DD' )
					}

				} else {
					return day.date.format( 'dd DD' )
				}
			},

			/**
			 */

			change_day( day_selected ) {

				if ( this.day.format( 'YYYY-MM-DD' ) != day_selected.value ) {
					window.location.href	= this.base_url + day_selected.value
				}

			},

			/**
			 */

			change_user	: function( selected ) {

				var _this	= this

				if ( !selected ) {
					return false
				}

				var user_id	= selected.value

				if ( user_id == 'false' ) {

					this.$store.commit( 'user/update_user', {
						id	: false,
						picks	: [],
						day	: false,
					})

				} else {

					this.loader( 'week', true )

					this.$store.dispatch( 'user/api_picks', {
						user_id	: user_id,
						callback	: function() {
							_this.loader( 'week' )
						},
					})

				}

			},

		},

		computed	: {

			/**
			 * Récupération des options pour la select utilisateur
			 */

			users_option() {

				var users	= this.$store.state.team.users
				var options	= []

				users.forEach(function( user ) {
					options.push({
						label	: user.pseudo,
						value	: user.id,
					})
				})

				return options

			},

			/**
			 */

			get_players() {
				return this.$store.state.players.originals
			},

			/**
			 */

			get_user_id() {
				return ( this.$store.state.user && this.$store.state.user.id ) ? this.$store.state.user.id : 'null'
			},

		},

		watch	: {

			get_players() {

				if ( this.is_phone() && this.$store.state.user.id && this.$refs.user_select ) {

					if ( !this.$refs.user_select.selected_option ) {
						this.$refs.user_select.manual_select_option( this.$store.state.user.id, true )
					}

				}

			},

		},

		mounted	: function() {
			
			var _this	= this

			var monday	= moment( this.day.valueOf() ).isoWeekday( 1 )

			/**
			 * Mise en place des jours de la semaine mais depuis le lundi (pas le dimanche)
			 */
			
			var weekday	= moment( monday.valueOf() )
			var weekdays	= Array.apply( null, Array( 7 ) ).map( function() {

				var valueof	= weekday.valueOf()
				weekday.add( 1, 'd' )
				return moment( valueof )
				
			})


			weekdays.forEach(function( weekday ) {
				
				_this.days.push({
					'date'	: weekday,
					'current'	: ( weekday.valueOf() == _this.day.valueOf() ) ? true : false,
				})

			})

			this.deck_before.date	= moment( monday.valueOf() )
			this.deck_before.date.subtract( 1, 'days' )

			this.deck_after.date	= moment( this.day.valueOf() ).isoWeekday( 7 )
			this.deck_after.date.add( 1, 'days' )

			var deck_before	= new Deck( this.deck_before.date )
			var deck_after	= new Deck( this.deck_after.date )

			this.deck_before.deck	= deck_before.number
			this.deck_after.deck	= deck_after.number

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	.days-desktop {

		display: flex;
		justify-content: flex-start;
		align-content: flex-start;
		flex-flow: row nowrap;
		width: 720px;

		a {

			display: block;
			flex: auto;
			max-width: 70px;
			padding: 5px 10px;
			margin-right: 10px;
			border: 2px solid #000;
			font-size: 0.875em;
			color: #000;
			text-transform: uppercase;
			text-align: center;

			&:last-child {
				margin-right: 0;
			}

			&.current,
			&:hover {
				background: $red;
				border-color: $red;
				color: #FFF;
			}

		}

	}

	#app.device-phone .days-mobile {

		display: flex;
		justify-content: space-between;

	}

	#app.device-phone .week-container {

		border: 0;
		background-color: transparent;

		.container-body {
			padding: 0;
		}

	}

	.vselect::v-deep {

		flex: calc( 50% - 5px );
		max-width: calc( 50% - 5px );
		height: 45px;

		&:first-child .vselect-container {
			border-left: 0;
		}

		&:last-child .vselect-container {
			border-right: 0;
		}

	}

</style>
