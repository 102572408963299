// define a mixin object
export default {

	methods	: {

		/**
		 * Affiche/cache le loader global
		 */

		loader( origin, show ) {

			// console.log( 'Loader from "' + origin + '"' + ' show=' + ( show ? 'yes' : 'no' ) )

			if ( show ) {
				document.getElementById( 'loader').classList.add( 'visible' )
			} else {
				document.getElementById( 'loader').classList.remove( 'visible' )
			}

			// if ( show && !document.getElementById( 'loader' ).classList.contains( 'visible' ) ) {
			// 	document.getElementById( 'loader').classList.add( 'visible' )
			// } else {
			// 	document.getElementById( 'loader' ).classList.remove( 'visible' )
			// }
			
		},

		/**
		 */
		
		change_user( selected ) {

			var _this	= this

			if ( !selected ) {
				return false
			}

			var user_id	= selected.value

			if ( user_id == 'false' ) {

				this.$store.commit( 'user/update_user', {
					id	: false,
					picks	: [],
					day	: false,
				})

			} else {

				this.loader( 'change_user', true )

				this.$store.dispatch( 'user/api_picks', {
					user_id	: user_id,
					callback	: function() {

						_this.loader( 'change_user' )

					},
				})

			}

		},
	
	},

	computed	: {

		/**
		 * ID de l'utilisateur en session
		 */

		current_user_id() {

			return localStorage.getItem( 'store.user' )

		},

	},

}